import React from "react";
import Home from "./pages/Home"
import './i18n-config/i18n'; // import i18n configuration


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "e-sim-e11a6.firebaseapp.com",
  databaseURL: "https://e-sim-e11a6-default-rtdb.firebaseio.com",
  projectId: "e-sim-e11a6",
  storageBucket: "e-sim-e11a6.appspot.com",
  messagingSenderId: "192636766943",
  appId: process.env.REACT_APP_APP_ID,
  measurementId: "G-NZHRXD3CLS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <Home/>
    
  );
}

export default App;
