import { Box, Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Phone1 from "../../Assets/Images/Phone1.png";
import Phone2 from "../../Assets/Images/Phone2.png";
import Phone3 from "../../Assets/Images/Phone3.png";
import Phone4 from "../../Assets/Images/Phone4.png";
import PhoneDefault from "../../Assets/Images/PhoneDefault.png";

import "./HowItWorks.css";

const HowItWorks = () => {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    afterChange: (current) => setCurrentSlide(current), // Track current slide
  };
  const [hoveredPhrase, setHoveredPhrase] = useState("default");
  const [currentSlide, setCurrentSlide] = useState(0);

  const steps = [
    {
      id: "1",
      title1: "GET YOUR ESIM",
      title2: "TO GO",
      label1: "Message us on WhatsApp",
      label2: "Choose your destination and data plan",
      label3: "Complete your purchase",
      imgSrc: Phone1,
    },
    {
      id: "2",
      title1: "INSTALL IT",
      title2: "BEFORE YOU GO",
      label1: "Receive your eSIM QR code via WhatsApp",
      label2: "Scan the QR code to activate your eSIM",
      label3: "Start using your data immediately",
      imgSrc: Phone2,
    },
    {
      id: "3",
      title1: "MANAGE YOUR DATA USAGE",
      title2: "ON THE GO",
      label1: "Keep track of your data consumption",
      label2: "Monitor data balance",
      label3: "Access from Anywhere, Anytime",
      imgSrc: Phone3,
    },
    {
      id: "4",
      title1: "TOP UP",
      title2: "AS YOU GO",
      label1: "Easily add more data to your plan via WhatsApp",
      label2: "Stay connected without interruptions",
      label3: "",
      imgSrc: Phone4,
    },
  ];

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }

      const listener = () => setMatches(media.matches);
      media.addEventListener("change", listener);
      return () => media.removeEventListener("change", listener);
    }, [matches, query]);

    return matches;
  };
  let isMobile = useMediaQuery(" (max-width:835px)");
  let isTablet = useMediaQuery("(min-width: 836px) and (max-width: 1024px)");

  return (
    <Box
      // className="content-why-choose"
      sx={{
        padding: 2,
        borderRadius: "8px",
        textAlign: "center",
      }}
    >
      {isMobile ? (
        <>
          <div className="section-title-how-it-works-mobile">
            <hr className="line-green-tall-mobile-right" />
            <Typography className="label-mobile">
              {" "}
              {t("How It Works?")}
            </Typography>
            <hr className="line-green-tall-mobile-left" />
          </div>
          <div className="content-why-choose">
            {/* First Slider */}

            {/* Second Slider */}
            <Slider {...settings}>
              <div className="card-content">
                <div>
                  <div className="img-mobile">
                    <img width={"268px"} height={"556px"} src={Phone1} />
                  </div>
                  <div
                    key={"1"}
                    className={`card-mobile`}
                    onMouseEnter={() => setHoveredPhrase("1")}
                    onMouseLeave={() => setHoveredPhrase("default")}
                    style={{ display: "flex" }}
                  >
                    <div
                      className={
                        currentSlide === 0
                          ? `number-step-mobile-green`
                          : `number-step-mobile`
                      }
                    >
                      {"1"}
                    </div>
                    <div style={{ display: "grid", textAlign: "left" }}>
                      <Typography
                        className={
                          currentSlide === 0
                            ? `title1-mobile-green`
                            : "title1-mobile"
                        }
                      >
                        {t("GET YOUR ESIM")}
                      </Typography>
                      <Typography
                        className={
                          currentSlide === 0
                            ? `title2-mobile-green`
                            : "title2-mobile"
                        }
                      >
                        {t("TO GO")}
                      </Typography>
                      <ul style={{margin:"0px",padding:!isMobile&&"0px 0px 0px 19px"}}>
                        <Typography
                          className={
                            currentSlide === 0
                              ? `list-mobile-green`
                              : "list-mobile"
                          }
                        >
                          <li>{t("Message us on WhatsApp")}</li>
                        </Typography>

                        <Typography
                          className={
                            currentSlide === 0
                              ? `list-mobile-green`
                              : "list-mobile"
                          }
                        >
                          <li>{t("Choose your destination and data plan")}</li>
                        </Typography>
                        <Typography
                          className={
                            currentSlide === 0
                              ? `list-mobile-green`
                              : "list-mobile"
                          }
                        >
                          <li> {t("Complete your purchase")} </li>
                        </Typography>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-content">
                <div>
                  {" "}
                  <div className="img-mobile">
                    <img width={"268px"} height={"556px"} src={Phone2} />
                  </div>
                  <div
                    key={"2"}
                    className={`card-mobile`}
                    onMouseEnter={() => setHoveredPhrase("2")}
                    onMouseLeave={() => setHoveredPhrase("default")}
                    style={{ display: "flex" }}
                  >
                    <div
                      className={
                        currentSlide === 1
                          ? `number-step-mobile-green`
                          : "number-step-mobile"
                      }
                    >
                      {"2"}
                    </div>
                    <div style={{ display: "grid", textAlign: "left" }}>
                      <Typography
                        className={
                          currentSlide === 1
                            ? `title1-mobile-green`
                            : "title1-mobile"
                        }
                      >
                        {t("INSTALL IT")}
                      </Typography>
                      <Typography
                        className={
                          currentSlide === 1
                            ? `title2-mobile-green`
                            : "title2-mobile"
                        }
                      >
                        {t("BEFORE YOU GO")}
                      </Typography>
                      <ul style={{margin:"0px",padding:!isMobile&&"0px 0px 0px 19px"}}>
                        <Typography
                          className={
                            currentSlide === 1
                              ? `list-mobile-green`
                              : "list-mobile"
                          }
                        >
                          <li>{t("Receive your eSIM QR code via WhatsApp")}</li>
                        </Typography>

                        <Typography
                          className={
                            currentSlide === 1
                              ? `list-mobile-green`
                              : "list-mobile"
                          }
                        >
                          <li>{t("Scan the QR code to activate your eSIM")}</li>
                        </Typography>
                        <Typography
                          className={
                            currentSlide === 1
                              ? `list-mobile-green`
                              : "list-mobile"
                          }
                        >
                          <li> {t("Start using your data immediately")} </li>
                        </Typography>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-content">
                <div>
                  {" "}
                  <div className="img-mobile">
                    <img width={"268px"} height={"556px"} src={Phone3} />
                  </div>
                  <div
                    key={"3"}
                    className={`card-mobile`}
                    onMouseEnter={() => setHoveredPhrase("3")}
                    onMouseLeave={() => setHoveredPhrase("default")}
                    style={{ display: "flex" }}
                  >
                    <div
                      className={
                        currentSlide === 2
                          ? `number-step-mobile-green`
                          : `number-step-mobile`
                      }
                    >
                      {"3"}
                    </div>
                    <div style={{ display: "grid", textAlign: "left" }}>
                      <Typography
                        className={
                          currentSlide === 2
                            ? `title1-mobile-green`
                            : "title1-mobile"
                        }
                      >
                        {t("MANAGE YOUR DATA USAGE")}
                      </Typography>
                      <Typography
                        className={
                          currentSlide === 2
                            ? `title2-mobile-green`
                            : "title2-mobile"
                        }
                      >
                        {t("ON THE GO")}
                      </Typography>
                      <ul style={{margin:"0px",padding:!isMobile&&"0px 0px 0px 19px"}}>
                        <Typography
                          className={
                            currentSlide === 2
                              ? `list-mobile-green`
                              : "list-mobile"
                          }
                        >
                          <li>{t("Keep track of your data consumption")}</li>
                        </Typography>

                        <Typography
                          className={
                            currentSlide === 2
                              ? `list-mobile-green`
                              : "list-mobile"
                          }
                        >
                          <li>{t("Monitor data balance")}</li>
                        </Typography>
                        <Typography
                          className={
                            currentSlide === 2
                              ? `list-mobile-green`
                              : "list-mobile"
                          }
                        >
                          <li> {t("Access from Anywhere, Anytime")} </li>
                        </Typography>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-content">
                <div>
                  {" "}
                  <div className="img-mobile">
                    <img width={"268px"} height={"556px"} src={Phone4} />
                  </div>
                  <div
                    key={"4"}
                    className={`card-mobile`}
                    onMouseEnter={() => setHoveredPhrase("4")}
                    onMouseLeave={() => setHoveredPhrase("default")}
                    style={{ display: "flex" }}
                  >
                    <div
                      className={
                        currentSlide === 3
                          ? `number-step-mobile-green`
                          : `number-step-mobile`
                      }
                    >
                      {"4"}
                    </div>
                    <div style={{ display: "grid", textAlign: "left" }}>
                      <Typography
                        className={
                          currentSlide === 3
                            ? `title1-mobile-green`
                            : "title1-mobile"
                        }
                      >
                        {t("MANAGE YOUR DATA USAGE")}
                      </Typography>
                      <Typography
                        className={
                          currentSlide === 3
                            ? `title2-mobile-green`
                            : "title2-mobile"
                        }
                      >
                        {t("ON THE GO")}
                      </Typography>
               <ul style={{margin:"0px",padding:!isMobile&&"0px 0px 0px 19px"}}>
                        <Typography
                          className={
                            currentSlide === 3
                              ? `list-mobile-green`
                              : `list-mobile`
                          }
                        >
                          <li>
                            {t(
                              "Easily add more data to your plan via WhatsApp"
                            )}
                          </li>
                        </Typography>

                        <Typography
                          className={
                            currentSlide === 3
                              ? `list-mobile-green`
                              : `list-mobile`
                          }
                        >
                          <li>{t("Stay connected without interruptions")}</li>
                        </Typography>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </Slider>
          </div>
        </>
      ) : (
        <div style={{ width: isTablet && "80%" }}>
          <div className="section-title-how-it-works">
            <hr className="line-green-tall" />
            <Typography className="label"> {t("How It Works?")}</Typography>
            <hr className="line-green-tall" />
          </div>

          <Grid2 container className="content-why-choose">
            <Grid2
              item
              size={{ lg: 6 }}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div className="steps">
                <div
                  key={"1"}
                  className={`step ${hoveredPhrase === "1" ? "hovered" : ""}`}
                  onMouseEnter={() => setHoveredPhrase("1")}
                  onMouseLeave={() => setHoveredPhrase("default")}
                  style={{ display: "flex" }}
                >
                  <div
                    className={`number-step ${
                      hoveredPhrase === "1"
                        ? "hovered"
                        : hoveredPhrase === "default"
                        ? "default-step1"
                        : ""
                    }`}
                  >
                    {"1"}
                  </div>
                  <div style={{ display: "grid", textAlign: "left" }}>
                    <Typography
                      className={`title1 ${
                        hoveredPhrase === "1"
                          ? "hovered"
                          : hoveredPhrase === "default"
                          ? "default-step1"
                          : ""
                      }`}
                    >
                      {t("GET YOUR ESIM")}
                    </Typography>
                    <Typography
                      className={`title2 ${
                        hoveredPhrase === "1"
                          ? "hovered"
                          : hoveredPhrase === "default"
                          ? "default-step1"
                          : ""
                      }`}
                    >
                      {t("TO GO")}
                    </Typography>
                    <ul style={{margin:"0px",padding:!isMobile&&"0px 0px 0px 19px"}}>
                      <Typography
                        className={`list ${
                          hoveredPhrase === "1"
                            ? "hovered"
                            : hoveredPhrase === "default"
                            ? "default-step1"
                            : ""
                        }`}
                      >
                        <li>{t("Message us on WhatsApp")}</li>
                      </Typography>

                      <Typography
                        className={`list ${
                          hoveredPhrase === "1"
                            ? "hovered"
                            : hoveredPhrase === "default"
                            ? "default-step1"
                            : ""
                        }`}
                      >
                        <li>{t("Choose your destination and data plan")}</li>
                      </Typography>
                      <Typography
                        className={`list ${
                          hoveredPhrase === "1"
                            ? "hovered"
                            : hoveredPhrase === "default"
                            ? "default-step1"
                            : ""
                        }`}
                      >
                        <li> {t("Complete your purchase")} </li>
                      </Typography>
                    </ul>
                  </div>
                </div>

                <div
                  key={"2"}
                  className={`step ${hoveredPhrase === "2" ? "hovered" : ""}`}
                  onMouseEnter={() => setHoveredPhrase("2")}
                  onMouseLeave={() => setHoveredPhrase("default")}
                  style={{ display: "flex" }}
                >
                  <div
                    className={`number-step ${
                      hoveredPhrase === "2"
                        ? "hovered"
                        : hoveredPhrase === "default"
                        ? "default"
                        : ""
                    }`}
                  >
                    {"2"}
                  </div>
                  <div style={{ display: "grid", textAlign: "left" }}>
                    <Typography
                      className={`title1 ${
                        hoveredPhrase === "2"
                          ? "hovered"
                          : hoveredPhrase === "default"
                          ? "default"
                          : ""
                      }`}
                    >
                      {t("INSTALL IT")}
                    </Typography>
                    <Typography
                      className={`title2 ${
                        hoveredPhrase === "2"
                          ? "hovered"
                          : hoveredPhrase === "default"
                          ? "default"
                          : ""
                      }`}
                    >
                      {t("BEFORE YOU GO")}
                    </Typography>
                    <ul style={{margin:"0px",padding:!isMobile&&"0px 0px 0px 19px"}}>
                      <Typography
                        className={`list ${
                          hoveredPhrase === "2" ? "hovered" : ""
                        }`}
                      >
                        <li>{t("Receive your eSIM QR code via WhatsApp")}</li>
                      </Typography>

                      <Typography
                        className={`list ${
                          hoveredPhrase === "2" ? "hovered" : ""
                        }`}
                      >
                        <li>{t("Scan the QR code to activate your eSIM")}</li>
                      </Typography>
                      <Typography
                        className={`list ${
                          hoveredPhrase === "2" ? "hovered" : ""
                        }`}
                      >
                        <li> {t("Start using your data immediately")} </li>
                      </Typography>
                    </ul>
                  </div>
                </div>

                <div
                  key={"3"}
                  className={`step ${hoveredPhrase === "3" ? "hovered" : ""}`}
                  onMouseEnter={() => setHoveredPhrase("3")}
                  onMouseLeave={() => setHoveredPhrase("default")}
                  style={{ display: "flex" }}
                >
                  <div
                    className={`number-step ${
                      hoveredPhrase === "3"
                        ? "hovered"
                        : hoveredPhrase === "default"
                        ? "default"
                        : ""
                    }`}
                  >
                    {"3"}
                  </div>
                  <div style={{ display: "grid", textAlign: "left" }}>
                    <Typography
                      className={`title1 ${
                        hoveredPhrase === "3"
                          ? "hovered"
                          : hoveredPhrase === "default"
                          ? "default"
                          : ""
                      }`}
                    >
                      {t("MANAGE YOUR DATA USAGE")}
                    </Typography>
                    <Typography
                      className={`title2 ${
                        hoveredPhrase === "3"
                          ? "hovered"
                          : hoveredPhrase === "default"
                          ? "default"
                          : ""
                      }`}
                    >
                      {t("ON THE GO")}
                    </Typography>
                    <ul style={{margin:"0px",padding:!isMobile&&"0px 0px 0px 19px"}}>
                      <Typography
                        className={`list ${
                          hoveredPhrase === "3" ? "hovered" : ""
                        }`}
                      >
                        <li>{t("Keep track of your data consumption")}</li>
                      </Typography>

                      <Typography
                        className={`list ${
                          hoveredPhrase === "3" ? "hovered" : ""
                        }`}
                      >
                        <li>{t("Monitor data balance")}</li>
                      </Typography>
                      <Typography
                        className={`list ${
                          hoveredPhrase === "3" ? "hovered" : ""
                        }`}
                      >
                        <li> {t("Access from Anywhere, Anytime")} </li>
                      </Typography>
                    </ul>
                  </div>
                </div>

                <div
                  key={"4"}
                  className={`step ${hoveredPhrase === "4" ? "hovered" : ""}`}
                  onMouseEnter={() => setHoveredPhrase("4")}
                  onMouseLeave={() => setHoveredPhrase("default")}
                  style={{ display: "flex" }}
                >
                  <div
                    className={`number-step ${
                      hoveredPhrase === "4"
                        ? "hovered"
                        : hoveredPhrase === "default"
                        ? "default"
                        : ""
                    }`}
                  >
                    {"4"}
                  </div>
                  <div style={{ display: "grid", textAlign: "left" }}>
                    <Typography
                      className={`title1 ${
                        hoveredPhrase === "4"
                          ? "hovered"
                          : hoveredPhrase === "default"
                          ? "default"
                          : ""
                      }`}
                    >
                      {t("TOP UP")}
                    </Typography>
                    <Typography
                      className={`title2 ${
                        hoveredPhrase === "4"
                          ? "hovered"
                          : hoveredPhrase === "default"
                          ? "default"
                          : ""
                      }`}
                    >
                      {t("AS YOU GO")}
                    </Typography>
                    <ul style={{margin:"0px",padding:!isMobile&&"0px 0px 0px 19px"}}>
                      <Typography
                        className={`list ${
                          hoveredPhrase === "4" ? "hovered" : ""
                        }`}
                      >
                        <li>
                          {t("Easily add more data to your plan via WhatsApp")}
                        </li>
                      </Typography>

                      <Typography
                        className={`list ${
                          hoveredPhrase === "4" ? "hovered" : ""
                        }`}
                      >
                        <li>{t("Stay connected without interruptions")}</li>
                      </Typography>
                    </ul>
                  </div>
                </div>
              </div>
            </Grid2>
            <Grid2
              item
              size={{ lg: 6 }}
              style={{ display: "flex", justifyContent: "left" }}
            >
              <div className="image-container">
                {steps.map((step) => (
                  <div>
                    <img
                      width={"369px"}
                      height="765px"
                      key={"2"}
                      src={hoveredPhrase === "default" ? PhoneDefault : step.imgSrc}
                      alt={step.title1}
                      className={`hover-image ${
                        hoveredPhrase === step.id || hoveredPhrase === "default"
                          ? "hover-image-visible"
                          : ""
                      }`}
                    />
                  </div>
                ))}
              </div>
            </Grid2>
          </Grid2>
        </div>
      )}
    </Box>
  );
};

export default HowItWorks;
