import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Button, Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Faq.css";

function Faq() {
  const { t } = useTranslation();
  const [question1, setQuestion1] = useState(false);
  const [question2, setQuestion2] = useState(false);
  const [question3, setQuestion3] = useState(false);
  const [question4, setQuestion4] = useState(false);

  const [question5, setQuestion5] = useState(false);
  const [question6, setQuestion6] = useState(false);
  const [question7, setQuestion7] = useState(false);
  const [question8, setQuestion8] = useState(false);
  const [question9, setQuestion9] = useState(false);
  const [question10, setQuestion10] = useState(false);

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }

      const listener = () => setMatches(media.matches);
      media.addEventListener("change", listener);
      return () => media.removeEventListener("change", listener);
    }, [matches, query]);

    return matches;
  };
  let isMobile = useMediaQuery("(max-width: 767px)");
  return (
    <>
      <Box
        className="content-faq"
        sx={{
          padding: 2,
          borderRadius: "8px",
        }}
      >
        <div className="section-title-faqs">
          <hr className={isMobile?"line-green-mobile":"line-green"} />
          <Typography
            className="label-second"
          >
            {t("Frequently Asked Questions")}
          </Typography>
          <hr className={isMobile?"line-green-mobile":"line-green"} />
        </div>
        <div style={{display:!isMobile&&"flex",justifyContent:!isMobile&&"center"}}>
        <div className="all-faq">
          <div
            onClick={() => setQuestion1(!question1)}
            className="content-question"
          >
            <div style={{ display: "flex" }}>
              {question1 ? (
                <RemoveCircleOutlineIcon className="faq-minus-icon" />
              ) : (
                <AddCircleOutlineIcon className="faq-plus-icon" />
              )}
              <Typography
                className={`faq-question ${question1 ? "selected" : ""}`}
              >
                {t("1. What is an eSIM and how does it work with Chat2eSIM?")}
              </Typography>
            </div>
            <Typography className={`faq-response ${question1 ? "show" : ""}`}>
              {t(
                "An eSIM is an embedded SIM that allows you to activate a cellular plan without needing a physical SIM card. With Chat2eSIM, you can easily purchase international eSIM travel data bundles via WhatsApp and stay connected wherever you go."
              )}
            </Typography>
          </div>

          <div
            onClick={() => setQuestion2(!question2)}
            className="content-question"
          >
            <div style={{ display: "flex" }}>
              {question2 ? (
                <RemoveCircleOutlineIcon className="faq-minus-icon" />
              ) : (
                <AddCircleOutlineIcon className="faq-plus-icon" />
              )}
              <Typography
                className={`faq-question ${question2 ? "selected" : ""}`}
              >
                {t(
                  "2. How can I purchase an international eSIM data bundle on Chat2eSIM?"
                )}
              </Typography>
            </div>
            <Typography className={`faq-response ${question2 ? "show" : ""}`}>
              {t(
                "To buy an eSIM data bundle, simply start a chat with Chat2eSIM on WhatsApp, select your destination, and choose a travel data plan that fits your needs. After completing your purchase, you’ll receive a QR code to install and activate the eSIM on your device. The eSIM is activated instantly, giving you seamless internet access throughout your trip."
              )}
            </Typography>
          </div>

          <div
            onClick={() => setQuestion3(!question3)}
            className="content-question"
          >
            <div style={{ display: "flex" }}>
              {question3 ? (
                <RemoveCircleOutlineIcon className="faq-minus-icon" />
              ) : (
                <AddCircleOutlineIcon className="faq-plus-icon" />
              )}
              <Typography
                className={`faq-question ${question3 ? "selected" : ""}`}
              >
                {t("3. How do I monitor my travel data usage on Chat2eSIM?")}
              </Typography>
            </div>
            <Typography className={`faq-response ${question3 ? "show" : ""}`}>
              {t(
                "You can easily check your data usage on Chat2eSIM by sending a message on WhatsApp. The platform will provide real-time information on your remaining data, so you can keep track of your usage at any time."
              )}
            </Typography>
          </div>

          <div
            onClick={() => setQuestion4(!question4)}
            className="content-question"
          >
            <div style={{ display: "flex" }}>
              {question4 ? (
                <RemoveCircleOutlineIcon className="faq-minus-icon" />
              ) : (
                <AddCircleOutlineIcon className="faq-plus-icon" />
              )}
              <Typography
                className={`faq-question ${question4 ? "selected" : ""}`}
              >
                {t("4. Can I top up my eSIM data plan through Chat2eSIM?")}
              </Typography>
            </div>
            <Typography className={`faq-response ${question4 ? "show" : ""}`}>
              {t(
                "Yes, you can conveniently top up your travel data bundle via WhatsApp using Chat2eSIM. Simply request a top-up in the chat, choose the additional data you need, and the balance will be updated instantly."
              )}
            </Typography>
          </div>

          <div
            onClick={() => setQuestion5(!question5)}
            className="content-question"
          >
            <div style={{ display: "flex" }}>
              {question5 ? (
                <RemoveCircleOutlineIcon className="faq-minus-icon" />
              ) : (
                <AddCircleOutlineIcon className="faq-plus-icon" />
              )}
              <Typography
                className={`faq-question ${question5 ? "selected" : ""}`}
              >
                {t(
                  "5.	How do I activate my eSIM for international travel using Chat2eSIM?"
                )}
              </Typography>
            </div>
            <Typography className={`faq-response ${question5 ? "show" : ""}`}>
              {t(
                "Once you’ve purchased your eSIM data bundle through Chat2eSIM on WhatsApp, you’ll receive a QR code. To activate it, scan the QR code using your smartphone’s eSIM settings. The eSIM will be installed and activated in minutes, giving you instant access to mobile data for your international travels."
              )}
            </Typography>
          </div>

          <div
            onClick={() => setQuestion6(!question6)}
            className="content-question"
          >
            <div style={{ display: "flex" }}>
              {question6 ? (
                <RemoveCircleOutlineIcon className="faq-minus-icon" />
              ) : (
                <AddCircleOutlineIcon className="faq-plus-icon" />
              )}
              <Typography
                className={`faq-question ${question6 ? "selected" : ""}`}
              >
                {t(
                  "6.	Can I use Chat2eSIM for WhatsApp calls and messages while abroad?"
                )}
              </Typography>
            </div>
            <Typography className={`faq-response ${question6 ? "show" : ""}`}>
              {t(
                "Yes, Chat2eSIM provides you with international data plans that allow you to use WhatsApp for chatting, voice calls, and video calls. With a stable data connection, you can easily stay in touch with family and friends while traveling."
              )}
            </Typography>
          </div>

          <div
            onClick={() => setQuestion7(!question7)}
            className="content-question"
          >
            <div style={{ display: "flex" }}>
              {question7 ? (
                <RemoveCircleOutlineIcon className="faq-minus-icon" />
              ) : (
                <AddCircleOutlineIcon className="faq-plus-icon" />
              )}
              <Typography
                className={`faq-question ${question7 ? "selected" : ""}`}
              >
                {t(
                  "7.	How do I know if my device is eSIM compatible for use with Chat2eSIM?"
                )}
              </Typography>
            </div>
            <Typography className={`faq-response ${question7 ? "show" : ""}`}>
              {t(
                `To check if your device supports eSIM, go to your smartphone’s settings under "Cellular" or "Mobile Data" and look for the option to add a mobile plan or eSIM. You can also dial *#06# to display your device's unique identifiers. If you see an EID (Embedded Identity Document) listed, your device is eSIM compatible and ready to use with Chat2eSIM.`
              )}
            </Typography>
          </div>

          <div
            onClick={() => setQuestion8(!question8)}
            className="content-question"
          >
            <div style={{ display: "flex" }}>
              {question8 ? (
                <RemoveCircleOutlineIcon className="faq-minus-icon" />
              ) : (
                <AddCircleOutlineIcon className="faq-plus-icon" />
              )}
              <Typography
                className={`faq-question ${question8 ? "selected" : ""}`}
              >
                {t(
                  "8.	What are the benefits of using Chat2eSIM for international travel?"
                )}
              </Typography>
            </div>
            <Typography className={`faq-response ${question8 ? "show" : ""}`}>
              {t(
                `Chat2eSIM offers seamless eSIM data plans for international travel, easy purchasing via WhatsApp, and real-time data monitoring. You can also take advantage of the refer-and-earn program to get credits toward future data purchases.`
              )}
            </Typography>
          </div>

          <div
            onClick={() => setQuestion9(!question9)}
            className="content-question"
          >
            <div style={{ display: "flex" }}>
              {question9 ? (
                <RemoveCircleOutlineIcon className="faq-minus-icon" />
              ) : (
                <AddCircleOutlineIcon className="faq-plus-icon" />
              )}
              <Typography
                className={`faq-question ${question9 ? "selected" : ""}`}
              >
                {t("9.	How does the Chat2eSIM referral program work?")}
              </Typography>
            </div>
            <Typography className={`faq-response ${question9 ? "show" : ""}`}>
              {t(
                `With Chat2eSIM’s referral program, you can refer friends to purchase their first eSIM data plan. When your friend buys a travel eSIM, you earn 10% credit from their purchase, which can be used toward your own data top-ups.`
              )}
            </Typography>
          </div>

          <div
            onClick={() => setQuestion10(!question10)}
            className="content-question"
          >
            <div style={{ display: "flex" }}>
              {question10 ? (
                <RemoveCircleOutlineIcon className="faq-minus-icon" />
              ) : (
                <AddCircleOutlineIcon className="faq-plus-icon" />
              )}
              <Typography
                className={`faq-question ${question10 ? "selected" : ""}`}
              >
                {t(
                  "10.	Can I purchase travel eSIM data for multiple countries with Chat2eSIM?"
                )}
              </Typography>
            </div>
            <Typography className={`faq-response ${question10 ? "show" : ""}`}>
              {t(
                `Yes, Chat2eSIM offers flexible international data plans for various countries & regions. Whether you're visiting multiple destinations, you can choose a regional plan that covers multiple countries, ensuring seamless connectivity throughout your trip.`
              )}
            </Typography>
          </div>
        </div>
        </div>
      </Box>

      <Grid2
        container
        style={{
          marginBottom: "0px",
          marginTop: !isMobile ? "100px" : "30px",
        }}
        className="background-still"
      >
        <Grid2 item size={{ xs: 12, lg: 8, md: 8, sm: 8 }}>
          <Typography
            className="still-have-question"
            style={{ fontSize: isMobile ? "25px" : "28px" }}
          >
            {t("Still Have Questions?")}
          </Typography>
          <Typography className="cant-find">
            {t(
              "Can’t find the answer you’re looking for? Please chat to our friendly team."
            )}
          </Typography>
        </Grid2>
        <Grid2
          item
          size={{ xs: 12, lg: 4, md: 4, sm: 4 }}
          style={{ textAlign: "right" }}
        >
          <Button
            variant={"contained"}
            onClick={() =>
              window.open(
                process.env.REACT_APP_WHATSAPP_CHAT,
                !isMobile ? "_blank" : "_self"
              )
            }
            className="get-started-btn-4"
            style={{ width: "100%" }}
          >
            {t("Get In Touch")}
          </Button>
        </Grid2>
      </Grid2>
    </>
  );
}

export default Faq;
