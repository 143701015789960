import React, { useEffect, useState } from "react";
import { Grid2, Typography, Link, Box } from "@mui/material";
import Chat2EsimFooter from "../../Assets/Images/Chat2EsimFooter.png";
import "./Footer.css";

import Facebook from "../../Assets/Images/Facebook.png";
import Instagram from "../../Assets/Images/Instagram.png";
import Twitter from "../../Assets/Images/Twitter.png";
import Linkedin from "../../Assets/Images/Linkedin.png";
import Tiktok from "../../Assets/Images/Tiktok.png";

function Footer() {

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }

      const listener = () => setMatches(media.matches);
      media.addEventListener("change", listener);
      return () => media.removeEventListener("change", listener);
    }, [matches, query]);

    return matches;
  };
  let isMobile = useMediaQuery("(max-width: 767px)");
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Box
        sx={{ backgroundColor: "#000", color: "white" }}
        className="container-footer"
      >
        <Grid2 container spacing={2} justifyContent="space-between">
          <Grid2 item size={{xs:12, lg:"auto",md:"auto"}}>
            <Typography variant="h6" component="div">
              <img
                width={isMobile?"132px":"194px"}
                height={isMobile?"91px":"136px"}
                src={Chat2EsimFooter}
                alt="Logo"
              />
            </Typography>
          </Grid2>

          <Grid2 item size={{xs:12, lg:"auto",md:"auto"}}>
            <Typography

              className="green-title"
            >
              International eSIMs
            </Typography>
            <Typography
              onClick={() =>
                window.open(process.env.REACT_APP_WHATSAPP_CHAT,!isMobile? "_blank":"_self")
              }
              className="white-label"
            >
              eSIM for Europe
            </Typography>
            <Typography
              onClick={() =>
                window.open(process.env.REACT_APP_WHATSAPP_CHAT,!isMobile? "_blank":"_self")
              }
              className="white-label"
            >
              eSIM for USA
            </Typography>
            <Typography
              onClick={() =>
                window.open(process.env.REACT_APP_WHATSAPP_CHAT,!isMobile? "_blank":"_self")
              }
              className="white-label"
            >
              eSIM for Turkey
            </Typography>
            <Typography
              onClick={() =>
                window.open(process.env.REACT_APP_WHATSAPP_CHAT,!isMobile? "_blank":"_self")
              }
              className="white-label"
            >
              eSIM for United Arab Emirates
            </Typography>
            <Typography
              onClick={() =>
                window.open(process.env.REACT_APP_WHATSAPP_CHAT,!isMobile? "_blank":"_self")
              }
              className="white-label"
            >
              eSIM for United Kingdom
            </Typography>
          </Grid2>

          <Grid2 item size={{xs:12, lg:"auto",md:"auto"}}>
            <Typography className="green-title">WhatsApp</Typography>
            <Typography
              onClick={() =>
                window.open(process.env.REACT_APP_WHATSAPP_CHAT,!isMobile? "_blank":"_self")
              }
              className="bold-label"
            >
              +44 7418 355595
            </Typography>
            <Typography className="green-title">Email Address</Typography>
            <Link
              href="mailto:support@Chat2eSIM.com"
              color="inherit"
              underline="hover"
              className="bold-label"
            >
              support@Chat2eSIM.com
            </Link>
          </Grid2>
          <Grid2 item size={{xs:12, lg:"auto",md:"auto"}}>
            <Box>
              <Typography className="green-title">Follow us on</Typography>
              <Link href="https://www.facebook.com/profile.php?id=61564192722608&mibextid=LQQJ4d" color="inherit" sx={{ marginRight: "10px" }} target="_blank" rel="noopener noreferrer">
                <img src={Facebook} alt="facebook" />
              </Link>
              <Link href="http://instagram.com/chat2esim" color="inherit" sx={{ marginRight: "10px" }} target="_blank" rel="noopener noreferrer">
                <img src={Instagram} alt="Instagram" />
              </Link>
              <Link href="http://twitter.com/Chat2eSIM" color="inherit" sx={{ marginRight: "10px" }} target="_blank" rel="noopener noreferrer">
                <img src={Twitter} alt="Twitter" />
              </Link>
              {/* <Link href="#" color="inherit" sx={{ marginRight: "10px" }}>
                <img src={Linkedin} alt="Linkedin" />
              </Link> */}
              <Link href="https://www.tiktok.com/@Chat2eSIM" color="inherit" target="_blank" rel="noopener noreferrer">
                <img src={Tiktok} alt="Tiktok" />
              </Link>
            </Box>
          </Grid2>
        </Grid2>
      </Box>

      <Typography className={isMobile?"copyright-mobile":"copyright" }>
      © Copyright {currentYear}, All Rights Reserved
      </Typography>
    </>
  );
}

export default Footer;
