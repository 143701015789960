import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en.json";
import ar from "./locale/ar.json";
import fr from "./locale/fr.json";

const allowedLanguages = ["en", "ar", "fr"];
let lng = "en";

const storageLanguage = localStorage.getItem("app_lang");
if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1)
    lng = storageLanguage;

i18n
    .use(initReactI18next)
    .init({
        resources: { en, ar, fr},
        lng,
        fallbackLng: "en",
        debug: process.env.NODE_ENV !== "production",
        ns: ["translations"], // This namespace should match what is inside the locale files
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: { useSuspense : true }
    });

export default i18n;