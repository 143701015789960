import { Box, Button, Container, Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BigImg from "../../Assets/Images/BigImg.png";
import Car from "../../Assets/Images/Car.png";
import Man from "../../Assets/Images/Man.png";
import Mountain from "../../Assets/Images/Mountain.png";
import WhatsappIcon from "../../Assets/Images/WhatsappIcon.png";
import GetStartedImg from "../../Assets/Images/GetStartedImg.png";

import "./GetStarted.css";
function GetStarted() {
  const { t } = useTranslation();

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }

      const listener = () => setMatches(media.matches);
      media.addEventListener("change", listener);
      return () => media.removeEventListener("change", listener);
    }, [matches, query]);

    return matches;
  };
  let isMobile = useMediaQuery(" (max-width: 835px)");
  let isTablet = useMediaQuery("(min-width: 836px) and (max-width: 1200px)");

  return (
    <Box
      className="box-container"
      sx={{
        borderRadius: "8px",
        maxWidth: "100%",

        // textAlign: "center",
      }}
    >
      <Container
        style={{ marginTop: isMobile ? "100px" : "180px", maxWidth: "100%" }}
        className={!isMobile && "container"}
      >
        <Grid2 style={{ width: "100%" }} container>
          {isMobile && !isTablet && (
            <div className="img-mobile-container">

            <img
              src={GetStartedImg}
              alt="GetStartedImg"
              width={"400px"}
              height={"350px"}
            />
            </div>

          )}
    

          <Grid2
            item
            size={{ xs: 12, md: 6, lg: 7 }}
            style={{ marginTop: isTablet &&isMobile ? "10px" : "80px" }}
            className="left-side"
          >
            <Typography className="travel-esim">
              {t("Travel eSIMs Made Simple!")}
            </Typography>

            <Typography className="big-title">
              {t("Get your eSIM in minutes via")}
            </Typography>
            <Typography className="big-title-green">
              {t("WhatsApp!")}
            </Typography>
            <Typography className="small-doc">
  {t(`Say goodbye to expensive roaming charges and complicated setups.`)}
  {!isMobile && !isTablet && <br />}
  {t(
    `With Chat2eSIM, you can purchase an eSIM data bundle for over 190 countries and regions,get real-time updates on your data usage, and top up your plan—all through WhatsApp.`
  )}
</Typography>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                margin: "30px 0px 40px 0px",
                alignItems:"center"
                // width: "max-content",
              }}
            >
              <hr style={{
                alignItems:"center"

              }} className="mini-line" />
              <Typography 
               style={{
                alignItems:"center"

              }}
              className="stay-connected">
                {t("Stay Connected Worldwide With Chat2eSIM!")}
              </Typography>
            </div>
            <Button
              variant={"contained"}
              onClick={() =>
                window.open(process.env.REACT_APP_WHATSAPP_CHAT,!isMobile? "_blank":"_self")
              }
              className="get-started-btn-2"
              style={{ width: isMobile ? "100%" : "246px" }}
            >
              {t("Get Started")}{" "}
            </Button>
          </Grid2>
          {!isMobile && !isTablet && (
            <Grid2 item size={{ xs: 12, md: 12, lg: 5 }}>
              <div style={{ position: "relative" }}>
                <div className="whatsapp">
                  <img src={WhatsappIcon} />
                </div>
                <div className="bigimg" >
                  <img src={BigImg} />
                </div>
                <div className="car">
                  <img src={Car} />
                </div>
                <div className="man">
                  <img src={Man} />
                </div>
                <div className="mountain">
                  <img src={Mountain} />
                </div>
              </div>
            </Grid2>
          )}
                {isTablet&&!isMobile && (
               <Grid2
               item
               size={{ xs: 12, md: 6, lg: 7 }}
              //  style={{AlignContent:"center" }}
               className="tablet-img-container"
             >
            <div className="img-tablet-container">
              <img
                src={GetStartedImg}
                alt="GetStartedImg"
                width={"450px"}
                height={"450px"}
                style={{marginRight:"-60px"}}
              />
            </div>
          </Grid2>

          )}
        </Grid2>
      </Container>
    </Box>
  );
}

export default GetStarted;
