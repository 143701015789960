import { Box, Container, CssBaseline, Grid2 } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GetStarted from "../components/GetStarted/GetStarted";
import Header from "../components/Header/Header";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import StayConnected from "../components/StayConnected/StayConnected";
import ChooseChat2eSIM from "../components/ChooseChat2eSIM/ChooseChat2eSIM";
import Faq from "../components/Faq/Faq";
import Footer from "../components/Footer/Footer";

function Home() {
  const { t } = useTranslation();
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div> 
      <CssBaseline />

      {/* Full-Width Header */}

      <Box style={{position:"fixed",width:"100%", zIndex:"1200" }}>
        <Header />
      </Box>

      {/* Centered Main Content */}
      <Grid2 item xs={12}>
        <GetStarted />
      </Grid2>
      <Grid2 item xs={12}>
        <HowItWorks />
      </Grid2>
      <Grid2 item xs={12}>
        <ChooseChat2eSIM />
      </Grid2>

      <Grid2 item xs={12}>
        <StayConnected />
      </Grid2>

      <Grid2 item xs={12}>
        <Faq />
      </Grid2>

      {/* Full-Width Footer */}
  
        <Grid2  item xs={12}>
          <Footer />
      </Grid2>
    </div>
  );
}

export default Home;
