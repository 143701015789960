import { Box, Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Image1 from "../../Assets/Images/image-1.png";
import Image2 from "../../Assets/Images/image-2.png";
import Image3 from "../../Assets/Images/image-3.png";
import Image4 from "../../Assets/Images/image-4.png";

import "./ChooseChat2eSIM.css";

function ChooseChat2eSIM() {
  const { t } = useTranslation();
  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }

      const listener = () => setMatches(media.matches);
      media.addEventListener("change", listener);
      return () => media.removeEventListener("change", listener);
    }, [matches, query]);

    return matches;
  };
  let isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <Box
      className="content"
      sx={{
        padding: 2,
        borderRadius: "8px",
        textAlign: "center",
      }}
    >
      <div className="background">
        <div className="section-title-why-choose">
          <hr className={isMobile?"line-green-mobile":"line-green"} />
          <Typography
            className="label-second"
          >
            {t("Why Choose Chat2eSIM?")}
          </Typography>
          <hr className={isMobile?"line-green-mobile":"line-green"} />
        </div>
        <div>
          <Grid2 container spacing={4}>
            <Grid2
              className="card"
              item
              size={{ xs: 12, sm: 6, md: 3, lg: 3 }}
              alignContent="center"
              style={{ textAlign: "center" }}
            >
              <img src={Image1} alt="logo" />
              <Typography className="title-card">
                {t("Global Coverage")}
              </Typography>
              <Typography className="description-card">
                {t("Stay connected in over 190 countries and regions.")}
              </Typography>
            </Grid2>
            <Grid2 className="card" item size={{ xs: 12, sm: 6, md: 3, lg: 3 }}>
              <img src={Image2} alt="logo" />
              <Typography className="title-card">
                {t("Affordable Plans")}
              </Typography>
              <Typography className="description-card">
                {t("Avoid hefty roaming charges with our competitive pricing.")}
              </Typography>
            </Grid2>
            <Grid2 className="card" item size={{ xs: 12, sm: 6, md: 3, lg: 3 }}>
              <img src={Image3} alt="logo" />
              <Typography className="title-card">
                {t("Seamless Management")}
              </Typography>
              <Typography className="description-card">
                {t("Handle all your eSIM needs through WhatsApp.")}
              </Typography>
            </Grid2>
            <Grid2 className="card" item size={{ xs: 12, sm: 6, md: 3, lg: 3 }}>
              <img src={Image4} alt="logo" />
              <Typography className="title-card">
                {t("Instant Activation")}
              </Typography>
              <Typography className="description-card">
                {t("Activate your eSIM immediately after purchase.")}
              </Typography>
            </Grid2>
          </Grid2>
        </div>
      </div>
    </Box>
  );
}

export default ChooseChat2eSIM;
